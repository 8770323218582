#call-to-action{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#call-to-action span{
    background-color: rgba(240, 248, 255, 0.30);
}
  /* round button of 24px */
.rnd-btn-1{
    border-radius: 24px 24px 24px 24px;
    background-color: rgba(13, 110, 253,0.75);
}

.card{
        width: 20rem;
        margin: 0 2.5% 0 2.5%;
        background-color: #212529;
        color:white;
}

.card-text{
    padding-top: 4.0%;
    padding-bottom: 4.0%;
    font-size: 16px;
    text-align: start;  
}

.card-img-top{
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.808);
}



.card a{
    color:inherit;
}

